import React from 'react';

import './Livestream.scss';

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export default function Livestream() {

  const isIOS = iOS();

  return (
    <main className="stream">
      {isIOS && <p>
        Watching on iOS? <a style={{fontWeight: 700}} href="https://drive.google.com/file/d/1XRdOj-p-CDTjGjmdH6pBoZfPL63TuTN3/view?usp=sharing">Click here to view the video!</a>  
      </p>}
      <iframe 
        className="videoContainer"
        src="https://drive.google.com/file/d/1XRdOj-p-CDTjGjmdH6pBoZfPL63TuTN3/preview"
        title="Mazlin and Aaron Wedding Video"
        scrolling="no"
        allowFullScreen>
      </iframe>
    </main>
  );
}
